import React, { useContext, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { CTAButton } from '../../components/CTAButton/CTAButton';
import { Titles } from '../../components/Titles/Titles';
import { RadioButton } from '../../components/RadioButton/RadioButton';
import { OnboardingFlowContext } from '../../OnboardingFlowContext';
import { defaultCaregiverFlow, defaultPatientFlow } from '../../userFlows';
import { OnboardingParams } from '../../types';
import { getProductName } from '../../utils/productHelpers';

export const WhoAreYouEnrolling: React.FC = () => {
  const {
    next, updateFormData, onboardingFormData, setSteps, resetFormData,
  } = useContext(OnboardingFlowContext);
  const { product } = useParams<OnboardingParams>();

  useEffect(() => {
    if (onboardingFormData.personEnrolling === 'caregiver') {
      setSteps(defaultCaregiverFlow);
    } else {
      setSteps(defaultPatientFlow);
    }
  });

  return (
    <div style={{ marginBottom: '40px' }}>
      <Titles
        title="Welcome! Are you enrolling yourself or a dependent?"
        subtitle={`Check if ${getProductName(product)} is covered by your insurance or employer.`
        + " If you're under the age of 18, please have a caretaker complete this step."}
      />
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <RadioButton
          checked={onboardingFormData.personEnrolling === 'patient'}
          name="enrollmentPerson"
          value="patient"
          textValue="Myself"
          onSelect={(selection) => {
            resetFormData();
            updateFormData({ personEnrolling: selection });
          }}
        />
        <RadioButton
          checked={onboardingFormData.personEnrolling === 'caregiver'}
          name="enrollmentPerson"
          value="caregiver"
          textValue="A dependent"
          onSelect={(selection) => {
            resetFormData();
            updateFormData({ personEnrolling: selection });
          }}
        />
      </div>
      <CTAButton
        text="Check Coverage"
        handleClick={next}
        isDisabled={onboardingFormData.personEnrolling === undefined}
      />
    </div>
  );
};
