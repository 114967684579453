import React from 'react';
import { Text } from '@bighealth/react-limbix-ui';

import { useParams } from 'react-router-dom';

import Styled from './DataSharingInfo.styles';
import { getProductPrivacyPolicy } from '../../utils/productHelpers';
import { OnboardingParams } from '../../types';

export const DataSharingInfo: React.FC = () => {
  const { product } = useParams<OnboardingParams>();
  return (
    <Text
      color="#363334"
      fontSize="14px"
      fontWeight="400"
      marginTop="24px"
      textAlign="center"
    >
      {'Your privacy is important to us. Read more in our '}
      <Styled.AnchorTag target="_blank" href={getProductPrivacyPolicy(product)}>
        Privacy Policy
      </Styled.AnchorTag>
      .
    </Text>
  );
};
