export const LANDING_PAGE_ROUTE = '/login';

export const SPARKRX_PDT_UID = '8dpNPSmSnyN3vxeBi4RtM9';
export const SLEEPIO_PDT_UID = 'BxgXBrgwswcX58wFNwhVfv';

// https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s01.html
// eslint-disable-next-line
export const EMAIL_VALIDATION_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

// https://ihateregex.io/expr/phone/
export const PHONE_VALIDATION_REGEX = /^[+]?\(?\d{3}\)?[-\s.]?\d{3}[-\s.]?\d{4,6}$/i;

// Validates this format: MM/DD/YYYY
export const DOB_VALIDATION_REGEX = /(?:(09|04|06|11)(\/)(0[1-9]|[12]\d|30)(\/)((?:19|20)\d\d))|(?:(01|03|05|07|08|10|12)(\/)(0[1-9]|[12]\d|3[01])(\/)((?:19|20)\d\d))|(?:02(\/)(?:(?:(0[1-9]|1\d|2[0-8])(\/)((?:19|20)\d\d))|(?:(29)(\/)((?:(?:19|20)(?:04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96))|2000))))/; // eslint-disable-line

// Validates zipcodes in these formats: ##### || #####-####
export const ZIPCODE_VALIDATION_REGEX = /^\d{5}(?:[-\s]\d{4})?$/;
