import React from 'react';

import { ListBoxWithIcons } from '../../components/ListBoxWithIcons/ListBoxWithIcons';

import SleepioDownloadIcon from '@/assets/SleepioDownloadIcon.svg';
import SleepioAccountIcon from '@/assets/SleepioAccountIcon.svg';
import SleepioMailIcon from '@/assets/SleepioMailIcon.svg';
import SleepioZzzIcon from '@/assets/SleepioZzzIcon.svg';

export const SleepioWhatsNext = () => (
  <ListBoxWithIcons
    title="What's next?"
    listItems={[
      {
        icon: SleepioMailIcon,
        listText: 'Get access code via email',
      },
      {
        icon: SleepioDownloadIcon,
        listText: 'Download the app',
      },
      {
        icon: SleepioAccountIcon,
        listText: 'Create your account',
      },
      {
        icon: SleepioZzzIcon,
        listText: 'Start seeing the benefits of Sleepio',
      },
    ]}
  />
);
