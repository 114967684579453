import { Box } from '@bighealth/react-limbix-ui';
import styled from 'styled-components';

const HeaderContainer = styled(Box)`
    width: 100%;
    border-bottom: 1px solid #DFE1E6;
`;

const MobileBackButton = styled(Box)`
    padding-left: 20px;
    align-content: center;
    flex-wrap: wrap;
    position: absolute;
    display: flex; 
`;

const Header = styled(Box)`
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 88px;
    align-items: center;
    flex-wrap: wrap;
`;

const SleepioHeader = styled(Box)`
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    height: 88px;
    align-items: center;
    flex-wrap: wrap;
`;

const SparkLogo = styled.img`
    position: absolute;
    width: 180px;
    margin-left: 56px;
`;

const SleepioLogo = styled.img`
    width: 122px;
`;

export default {
  HeaderContainer,
  MobileBackButton,
  Header,
  SparkLogo,
  SleepioHeader,
  SleepioLogo,
};
