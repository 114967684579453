import { Box } from '@bighealth/react-limbix-ui';
import styled, { css } from 'styled-components/macro';

const ButtonContainer = styled(Box)`
  display: flex;
  justify-content: center;
  flex: 1;
  margin-top: 40px;
`;

const Button = styled.button<{disabled: boolean}>`
width: fit-content;
padding: 16px 24px;
border: none;
padding-bottom: 16px;
background-color: ${(props) => props.theme.button.primary.backgroundColor};
border-radius: 8px;
text-align: center;
font-size: 18px;
font-weight: 500;
${(props) => props.disabled && css`
    background-color: ${props.theme.button.primary.disabledBackgroundColor};
`}
&:hover {
    cursor: pointer;
}
`;

const Text = styled.div<{disabled: boolean}>`
  font-family: "GT Walsheim";
  color: ${(props) => props.theme.button.primary.textColor};
  ${(props) => props.disabled && css`
    color: ${props.theme.button.primary.disabledTextColor};
  `}
  width: 187px;
  text-align: center;
`;

export default {
  ButtonContainer,
  Button,
  Text,
};
