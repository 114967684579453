import React from 'react';

import Styled from './Header.styles';

import SleepioLogo from '@/assets/SleepioLogoIcon.svg';

export const SleepioHeader: React.FC = () => (
  <Styled.HeaderContainer>
    <Styled.SleepioHeader>
      <Styled.SleepioLogo src={SleepioLogo} />
    </Styled.SleepioHeader>
  </Styled.HeaderContainer>
);
