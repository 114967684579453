import React, { useContext } from 'react';

import { useParams } from 'react-router-dom';

import { Titles } from '../../components/Titles/Titles';
import { CTAButton } from '../../components/CTAButton/CTAButton';
import { ListBoxWithIcons } from '../../components/ListBoxWithIcons/ListBoxWithIcons';
import { stepContent } from './stepContent';
import { OnboardingFlowContext } from '../../OnboardingFlowContext';

import { OnboardingParams } from '../../types';

import { SleepioWhatsNext } from './SleepioWhatsNext';

import AnswerQuestionsIcon from '@/assets/AnswerQuestionsIcon.svg';
import BenefitsOfSparkIcon from '@/assets/BenefitsOfSparkIcon.svg';
import DownloadTheAppIcon from '@/assets/DownloadTheAppIcon.svg';

export const CoverageConfirmation: React.FC = () => {
  const { next, onboardingFormData } = useContext(OnboardingFlowContext);
  const { product } = useParams<OnboardingParams>();
  const {
    title,
    subtitle,
    listTitle,
    listItemText1,
    listItemText2,
    listItemText3,
  } = stepContent(onboardingFormData.personEnrolling, product);
  let listBox = (
    <ListBoxWithIcons
      title={listTitle}
      listItems={[
        {
          icon: AnswerQuestionsIcon,
          listText: listItemText1,
        },
        {
          icon: DownloadTheAppIcon,
          listText: listItemText2,
        },
        {
          icon: BenefitsOfSparkIcon,
          listText: listItemText3,
        },
      ]}
    />
  );

  if (product === 'sleepio') {
    listBox = <SleepioWhatsNext />;
  }

  return (
    <>
      <Titles
        title={title}
        subtitle={subtitle}
      />
      {listBox}
      <CTAButton
        text="Continue"
        handleClick={next}
        isDisabled={false}
      />
    </>
  );
};
