import React, { ReactElement, useContext } from 'react';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { useParams } from 'react-router-dom';

import { BreadCrumbNav } from '../BreadCrumbNav/BreadCrumbNav';

import { OnboardingFlowContext } from '../../OnboardingFlowContext';

import Styled from './Header.styles';

import { OnboardingParams } from '../../types';

import { SleepioHeader } from './SleepioHeader';

import { useWindowDimensions } from '@/hooks/useWindowDimensions';
import SparkDirectLogo from '@/assets/SparkDirectLogo.svg';

interface HeaderProps {
    steps: {
        element: ReactElement,
        breadCrumb: string,
    }[];
    currentStep: {
        element: ReactElement,
        breadCrumb: string,
    };
    currentStepIndex: number
}

export const Header: React.FC<HeaderProps> = ({ steps, currentStep, currentStepIndex }) => {
  const { width } = useWindowDimensions();
  const { back } = useContext(OnboardingFlowContext);
  const { product } = useParams<OnboardingParams>();

  if (product === 'sleepio') {
    return <SleepioHeader />;
  }

  return (
    <Styled.HeaderContainer>
      <Styled.Header>
        {width > 950 ? (
          <Styled.SparkLogo src={SparkDirectLogo} />
        ) : (
          <Styled.MobileBackButton
            onClick={back}
          >
            <ArrowBackIosIcon
              fontSize="large"
            />
          </Styled.MobileBackButton>
        )}
        <BreadCrumbNav
          steps={steps}
          currentStep={currentStep}
          currentStepIndex={currentStepIndex}
        />
      </Styled.Header>
    </Styled.HeaderContainer>
  );
};
