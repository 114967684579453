import React from 'react';

import Styled from './CoverageRejection.styles';

import SleepioSighing from '@/assets/SleepioSighing.svg';

type CoverageRejectionProps = {
  isCaregiver: boolean;
};

export const SleepioCoverageRejection: React.FC<CoverageRejectionProps> = () => (
  <>
    <Styled.Title>
      We’re sorry!
    </Styled.Title>
    <Styled.Text>
      You are not eligible for Sleepio through your insurance or employer.
    </Styled.Text>
    <Styled.Text>
      If you have any questions or need technical support, please contact us at
      {' '}
      <Styled.AnchorTag href="mailto:sleepio@bighealth.com">sleepio@bighealth.com</Styled.AnchorTag>
    </Styled.Text>

    <Styled.Gap />
    <Styled.ImageContainer>
      <Styled.LimbotRainyDay src={SleepioSighing} />
    </Styled.ImageContainer>
    <Styled.Gap />
    <Styled.Subtitle>
      Experiencing a mental health emergency?
    </Styled.Subtitle>
    <Styled.Text>
      {`If you find yourself feeling hopeless or develop thoughts of 
        harming yourself at any point, please do the following:`}
    </Styled.Text>
    <Styled.List>
      <Styled.ListItem>
        <Styled.Text>
          For medical or mental health emergencies,
          {' '}
          <Styled.BoldText>dial 911</Styled.BoldText>
          {' '}
          or go to your nearest emergency room
        </Styled.Text>
      </Styled.ListItem>
      <Styled.ListItem>
        <Styled.Text>For questions about your physical or mental health, contact your doctor</Styled.Text>
      </Styled.ListItem>
      <Styled.ListItem>
        <Styled.Text>For mental health crisis support, use one of the crisis resources below</Styled.Text>
      </Styled.ListItem>
    </Styled.List>
    <Styled.BoxContainer>
      <Styled.BoldText>
        National Suicide Prevention Lifeline
      </Styled.BoldText>
      <Styled.Text>
        <Styled.PhoneTag href="tel:988">
          Call 988
        </Styled.PhoneTag>
      </Styled.Text>
      <Styled.Text>
        Chat online at:
        {' '}
        <Styled.AnchorTag href="www.988lifeline.org/chat/">www.988lifeline.org/chat/</Styled.AnchorTag>
      </Styled.Text>
    </Styled.BoxContainer>
    <Styled.BoxContainer>
      <Styled.BoldText>
        Crisis Text Line
      </Styled.BoldText>
      <Styled.Text>
        Text HOME to
        {' '}
        <Styled.PhoneTag href="tel:741741">741741</Styled.PhoneTag>
      </Styled.Text>
    </Styled.BoxContainer>
    <Styled.Gap />
    <Styled.Subtitle>
      Additional resources
    </Styled.Subtitle>
    <Styled.Gap />
    <Styled.BoldText>
      Substance Abuse and Mental Health Services Administration
    </Styled.BoldText>
    <Styled.Text>
      <Styled.AnchorTag href="https://www.samhsa.gov/find-help/national-helpline">
        https://www.samhsa.gov/find-help/national-helpline
      </Styled.AnchorTag>
    </Styled.Text>
    <Styled.Gap />
    <Styled.BoldText>
      The American Psychological Association
    </Styled.BoldText>
    <Styled.Text>
      <Styled.AnchorTag href="https://locator.apa.org/">
        https://locator.apa.org/
      </Styled.AnchorTag>
    </Styled.Text>
    <Styled.Gap />
    <Styled.BoldText>
      American Academy of Sleep Medicine
    </Styled.BoldText>
    <Styled.Text>
      <Styled.AnchorTag href="https://sleepeducation.org/">
        https://sleepeducation.org/
      </Styled.AnchorTag>
    </Styled.Text>
    <Styled.Gap />
    <Styled.Text>
      {`Big Health is not responsible for any actions or inactions between you and any third-party service provider. 
        You acknowledge and agree that Big Health is not responsible for and expressly disclaims all liability for 
        the actions or inactions of any third-party service provider.`}
    </Styled.Text>
  </>
);
