import { Product } from '../types';

import { sparkTheme } from '../themes/sparkTheme';
import { sleepioTheme } from '../themes/sleepioTheme';

import { SLEEPIO_PDT_UID, SPARKRX_PDT_UID } from '@/utils/constants';

export const getProductName = (product: Product) => {
  switch (product) {
  case 'spark':
    return 'Spark Direct';
  default:
    return 'Sleepio';
  }
};

export const getPdtUid = (product: Product) => {
  switch (product) {
  case 'spark':
    return SPARKRX_PDT_UID;
  default:
    return SLEEPIO_PDT_UID;
  }
};

export const getProductId = (product: Product) => {
  switch (product) {
  case 'spark':
    return 4;
  default:
    return 1;
  }
};

export const getTheme = (product: Product) => {
  switch (product) {
  case 'spark':
    return sparkTheme;
  default:
    return sleepioTheme;
  }
};

export const getProductEmail = (product: Product) => {
  switch (product) {
  case 'spark':
    return 'spark@bighealth.com';
  default:
    return 'sleepio@bighealth.com';
  }
};

export const getProductTermsAndConditions = (product: Product) => {
  switch (product) {
  case 'spark':
    return 'https://www.bighealth.com/spark-direct-terms-and-conditions/';
  default:
    return 'https://info.sleepio.com/terms';
  }
};

export const getProductPrivacyPolicy = (product: Product) => {
  switch (product) {
  case 'spark':
    return 'https://www.bighealth.com/spark-direct-privacy-policy/';
  default:
    return 'https://info.sleepio.com/privacy';
  }
};
