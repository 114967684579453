import React from 'react';
import { Text } from '@bighealth/react-limbix-ui';

import { useParams } from 'react-router-dom';

import { OnboardingPopover } from '../OnboardingPopover';

import { OnboardingParams } from '@/pages/Onboarding/types';
import { getProductEmail, getProductName } from '@/pages/Onboarding/utils/productHelpers';

const caregiverPopoverContent = () => {
  const { product } = useParams<OnboardingParams>();
  return (
    <>
      <Text
        fontFamily="GT Walsheim"
        color="#363334"
        fontSize="24px"
        fontWeight="700"
      >
        Why do we need this info?
      </Text>
      <Text
        fontFamily="GT Walsheim"
        color="#363334"
        fontSize="18px"
        marginTop="24px"
      >
        {
          `How your dependent interacts with ${getProductName(product)} is confidential. 
        However, we are legally required to break confidentiality and 
        disclose your dependent’s information in the following circumstances:`
        }
      </Text>
      <ul
        style={{
          fontFamily: 'GT Walsheim',
          color: '#363334',
          fontWeight: '600',
          fontSize: '18px',
          marginTop: '24px',
        }}
      >
        <li>To prevent harm to your dependent or others</li>
        <li>To report suspected child or elder abuse</li>
      </ul>
      <Text
        fontFamily="GT Walsheim"
        color="#363334"
        fontSize="18px"
        marginTop="24px"
      >
        {
          `If your dependent enters text into the app that seems 
        to show potential risk, a popup alert to be presented to 
        them along with safety resources. This also alerts a Big 
        Health staff member who will review the text and decide 
        if they may need to reach out and ensure safety.`
        }
        {' '}
        <b>
          {
            `In the event of serious safety risk, we will notify a 
          caregiver and we may contact emergency services.`
          }
        </b>
      </Text>
      <Text
        fontFamily="GT Walsheim"
        color="#363334"
        fontSize="18px"
        marginTop="24px"
      >
        If you have any questions, please contact us at
        {' '}
        {getProductEmail(product)}
      </Text>
    </>
  );
};

const patientPopoverContent = () => {
  const { product } = useParams<OnboardingParams>();
  return (
    <>
      <Text
        fontFamily="GT Walsheim"
        color="#363334"
        fontSize="24px"
        fontWeight="700"
      >
        Why do we need this info?
      </Text>
      <Text
        fontFamily="GT Walsheim"
        color="#363334"
        fontSize="18px"
        marginTop="24px"
      >
        {
          `The text you enter and how you engage with ${getProductName(product)} is confidential 
        and meant for only you. However, we are legally required to break 
        confidentiality and disclose your information in the following circumstances:`
        }
      </Text>
      <ul
        style={{
          fontFamily: 'GT Walsheim',
          color: '#363334',
          fontWeight: '600',
          fontSize: '18px',
          marginTop: '24px',
        }}
      >
        <li>To prevent harm to you or others</li>
        <li>To report suspected child or elder abuse</li>
      </ul>
      <Text
        fontFamily="GT Walsheim"
        color="#363334"
        fontSize="18px"
        marginTop="24px"
      >
        {
          `If you enter text into ${getProductName(product)} that seems to show potential risk, 
        a popup alert will be presented to you along with safety resources. 
        A Big Health staff member will also be alerted, and they will review 
        the text and decide if they need to reach out to ensure safety.`
        }
        {' '}
        <b>
          In the event of serious safety risk, we may contact emergency services.
        </b>
      </Text>
      <Text
        fontFamily="GT Walsheim"
        color="#363334"
        fontSize="18px"
        marginTop="24px"
      >
        If you have any questions, please contact us at
        {' '}
        {getProductEmail(product)}
      </Text>
    </>
  );
};

interface WhyDoWeNeedThisInfoProps {
  personEnrolling: 'patient' | 'caregiver';
  popoverOpen: boolean;
  setPopoverOpen: (arg0: boolean) => void;
}

export const WhyDoWeNeedThisInfo: React.FC<WhyDoWeNeedThisInfoProps> = ({
  personEnrolling,
  popoverOpen,
  setPopoverOpen,
}) => (
  <OnboardingPopover
    popoverOpen={popoverOpen}
    setPopoverOpen={setPopoverOpen}
  >
    {personEnrolling === 'caregiver' && caregiverPopoverContent()}
    {personEnrolling === 'patient' && patientPopoverContent()}
  </OnboardingPopover>
);
