import { Product } from '../../types';
import { getProductName } from '../../utils/productHelpers';

export const stepContent = (personEnrolling: 'patient' | 'caregiver', product: Product) => {
  if (personEnrolling === 'caregiver') {
    return {
      title: 'Tell us more about your dependent',
      subtitle: 'Why do we need this info?',
      emailTitle: 'Email address',
      infoTitle: "Dependent's Info",
      emailPlaceholder: "Dependent's email",
      phoneNumberPlaceholder: "Dependent's phone number",
      streetAddressPlaceholder: "Dependent's street address",
      cityPlaceholder: "Dependent's city",
    };
  }
  return {
    title: 'Next, tell us more about you',
    subtitle: 'Why do we need this info?',
    emailTitle: 'Email address',
    emailSubtitle: `Please enter a valid email address to receive your access code to ${getProductName(product)}.`,
    infoTitle: product === 'spark' ? 'Your Info' : 'Contact info',
    emailPlaceholder: 'Email',
    phoneNumberPlaceholder: 'Phone number',
    streetAddressPlaceholder: 'Street address',
    cityPlaceholder: 'City',
  };
};
